<template>
  <div class="home">
    <div class="content-wrap">

      <h1>Unsere Fahrzeuge</h1>

      <p> InTime ist Ihr günstiger PKW / Transporter Verleih und setzt mit seiner Fahrzeugflotte Maßstäbe. Soll es ein günstiger Pkw oder eher ein geräumiger Transporter sein? Bei InTime können Sie günstig Transporter und PKW mieten, die für nahezu jede Anforderung geeignet sind.</p>

      <div v-for="vehicle in vehicles" v-bind:key="vehicle.id" class="vehicle-wrap cf">
        <div class="vehicle-img">
          <p v-if="vehicle.image">
            <img :src="vehicle.image" width="400" height="auto">
          </p>
          <p v-else>
            <img src="https://placehold.co/400x240?text=Auto+Bild+Platzhalter">
          </p>
        </div>
        <div class="vehicle-body">
          <h3 style="margin-bottom:20px">{{ vehicle.name }} <small>{{ vehicle.tagline }}</small></h3>
          <div v-if="vehicle.seats > 0" class="vc-w">
            <span class="material-icons">people</span>
            <span>{{ vehicle.seats }} Sitze</span>
          </div>
          <div v-if="vehicle.doors > 0" class="vc-w">
            <span class="material-icons">sensor_door</span>
            <span>{{ vehicle.doors }} Türen</span>
          </div>
          <div v-if="vehicle.luggage > 0" class="vc-w">
            <span class="material-icons">work</span>
            <span>{{ vehicle.luggage }} Gepäck</span>
          </div>
          <div v-if="vehicle.drive != 'not_any'" class="vc-w">
            <span class="material-icons">directions_car</span>
            <span v-if="vehicle.drive == 'manual'">Gangschaltung</span>
            <span v-if="vehicle.drive == 'automatic'">Automatik</span>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'vehicles',
  data () {
    return {
      vehicles: [],
    }
  },
  methods: {
    get_vehicles() {

      axios.get(process.env.VUE_APP_BASE_API+'/v1/vehicle_categories', {})
      .then(response => {
        console.log(response);
        this.vehicles = response.data.vehicle_categories;
      })
      .catch(error => {
        console.log(error);
      })

    }
  },
  mounted () {
    this.get_vehicles();
  }
}
</script>
